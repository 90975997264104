import store from '@/store';
import api from '@/requests';

// #region Вспомогательные функции
// Функция для получения области координат
function getCoordinateArea(bounds) {
    const [[minLat, minLng], [maxLat, maxLng]] = bounds;
    return {
        minLat,
        minLng,
        maxLat,
        maxLng
    };
}

// Функция для обработки отбор запроса
function handleRequestError(error) {
  if (error.message?.includes('Timeout')) {
    console.warn('Время ожидания истекло:', error);
  } else if (error.name === 'AbortError') {
    console.warn('Запрос был отменён:', error);
  } else {
    console.error('Произошла ошибка:', error);
  }
}
// #endregion

// #region Получение сущностей
async function getEntities(layer, bounds, signal) {
  const coordinateArea = getCoordinateArea(bounds);
  let items = [];

  try {
    if (layer === 'partners') {
      items = await api.partners.listByCoordinateArea(coordinateArea, signal);
    } else if (layer === 'objects') {
      items = await api.objects.list(coordinateArea, signal);
    }

    if (!signal || !signal.aborted) {
      store.dispatch('entity/setEntities', items);
    } else {
      console.warn('Запрос был отменён');
    }
  } catch (error) {
    handleRequestError(error);
  }
}

// Функция для получения сущностей
async function fetchEntities(signal, isShowLoader = true) {
  if (isShowLoader) {
    store.dispatch('loader/startLoading'); // Запуск индикатора загрузки
  }

  try {
    const layer = store.getters['layer/layer'];
    const bounds = await store.dispatch('map/waitForBounds'); // Асинхронное ожидание bounds

    await getEntities(layer, bounds, signal);
  } catch (error) {
    handleRequestError(error);
  } finally {
    if (isShowLoader) {
      store.dispatch('loader/stopLoading'); // Остановка индикатора загрузки
    }
  }
}
// #endregion

// Функция для фильтрации сущностей
async function fetchEntitiesFilter(signal) {
  const layer = store.getters['layer/layer'];
  const bounds = await store.dispatch('map/waitForBounds');
  const filter = store.getters['filter/filterValues'];
  const coordinateArea = getCoordinateArea(bounds);

  try {
    let data = null;

    if (layer === 'partners') {
      data = await api.partners.filter(coordinateArea, filter, signal);
    } else if (layer === 'objects') {
      data = await api.objects.filter(coordinateArea, filter, signal);
    }

    if (!signal || !signal.aborted) {
      store.dispatch('entity/setEntities', data.items);
      store.dispatch('filter/setFilterId', data.meta.id);
      store.dispatch('filter/setCount', data.meta.count);
    } else {
      console.warn('Запрос был отменён');
    }
  } catch (error) {
    handleRequestError(error);
  }
}

// Функция для фильтрации сущностей по идентификатору
async function fetchEntityFilterById(id, signal) {
  const layer = store.getters['layer/layer'];
  const bounds = await store.dispatch('map/waitForBounds');
  const coordinateArea = getCoordinateArea(bounds);

  try {
    if (layer === 'partners') {
      const data = await api.partners.filterById(id, coordinateArea, signal);

      if (!signal || !signal.aborted) {
        store.dispatch('filter/setFilterId', data.meta.id);
        store.dispatch('filter/setCount', data.meta.count);
        store.dispatch('filter/selectDirection', data.meta.filters.directions ?? []);
        store.dispatch('filter/selectPartnerType', data.meta.filters.partnerTypes ?? []);
        store.dispatch('filter/selectPartnerAttribute', data.meta.filters.partnerAttributes ?? []);
        store.dispatch('filter/selectProduct', data.meta.filters.products ?? []);
        store.dispatch('filter/selectCertified', data.meta.filters.certified ?? null);
        store.dispatch('entity/setEntities', data.items);
      } else {
        console.warn('Запрос был отменён');
      }
    } else if (layer === 'objects') {
      const data = await api.objects.filterById(id, coordinateArea, signal);

      if (!signal || !signal.aborted) {
        store.dispatch('filter/setFilterId', data.meta.id);
        store.dispatch('filter/setCount', data.meta.count);
        store.dispatch('filter/selectDirection', data.meta.filters.directions ?? []);
        store.dispatch('filter/setObjectTypes', data.meta.filters.objectTypes ?? []);
        store.dispatch('filter/selectProduct', data.meta.filters.products ?? []);
        store.dispatch('filter/selectCertified', data.meta.filters.certified ?? null);
        store.dispatch('entity/setEntities', data.items);
      } else {
        console.warn('Запрос был отменён');
      }
    }
  } catch (error) {
    handleRequestError(error);
  }
}

// #region Поиск сущностей
// Функция для поиска сущностей
async function fetchSearch(layer, query, bounds, signal) {
    const coordinateArea = getCoordinateArea(bounds);

    try {
      let data = null;

        if (layer === 'partners') {
            data = await api.partners.searchByCoordinate(query, coordinateArea, signal);
        } else {
          data = await api.objects.search(query, coordinateArea, signal);
        }

        if (!signal || !signal.aborted) {
          store.dispatch('entity/setEntities', data.items ?? []);
          store.dispatch('filter/setCount', data.meta.count);
      } else {
          console.warn('Запрос был отменён');
      }
    } catch (error) {
      handleRequestError(error);
    }
}

// Функция для поиска сущностей
async function fetchEntitiesSearch(signal = undefined, isShowLoader = true) {
  if (isShowLoader) {
    store.dispatch('loader/startLoading');
  }

  try {
    const layer = store.getters['layer/layer'];
    const query = store.getters['search/searchQuery'];
    const bounds = await store.dispatch('map/waitForBounds');

    await fetchSearch(layer, query, bounds, signal);
  } catch (error) {
    if (error.message.includes('Timeout')) {
      console.warn('Время ожидания bounds истекло:', error);
    } else if (error.name === 'AbortError') {
      console.warn('Запрос был отменён:', error);
    } else {
      console.error('Ошибка при получении сущностей:', error);
    }
  } finally {
    if (isShowLoader) {
      store.dispatch('loader/stopLoading');
    }
  }
}
// #endregion

const entity = {
    list: fetchEntities,
    filter: fetchEntitiesFilter,
    filterById: fetchEntityFilterById,
    search: fetchEntitiesSearch
};

export default entity;
