const baseURL = process.env.BASE_URL;

const getBorderColors = (directions) => (
    Array.isArray(directions)
        ? [...new Set(directions.map(item => item.color))]
        : []
);

const getSrc = ({ image, link }, directionLength, layer) => {
    if (layer === 'objects' || directionLength > 1) {
        return '/img/logo.svg';
    }

    return link === null ? `${baseURL}api/v1/static/${image}/${image}.svg` : link;
};

const getIcon = (src, isFill, fillColor) => `<svg data-src="${src}" fill="${isFill ? '#fff' : fillColor}" class="map-marker__logo"></svg>`;

const getPoint = (isVisited, numberColors, color) => `<div class="map-marker__point ${isVisited ? 'map-marker__point--visited' : ''}" style="background-color: ${numberColors === 1 ? color : '#4E4E4E'}"></div>`;

const getBorder = (borderColors, isHovered, layer, isActive, borderWidth) => {
    switch (borderColors.length) {
        case 2:
            return `<div class="map-marker__border ${isHovered ? 'hovered' : ''}">
              <svg viewBox="0 0 70 70">
                <circle cx="35" cy="35" r="35" fill="${borderColors[0]}"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0V70Z" fill="${borderColors[1]}"/>
                <circle cx="35" cy="35" r="28" fill="white"/>
              </svg>
            </div>`;
        case 3:
            return `<div class="map-marker__border ${isHovered ? 'hovered' : ''}">
              <svg viewBox="0 0 70 70">
                <circle cx="35" cy="35" r="35" fill="${borderColors[0]}"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0V70Z" fill="${borderColors[1]}"/>
                <path d="M35 0C19.5128 0 6.375 10.059 1.76352 24L35 35L68.2365 24C63.625 10.059 50.4872 0 35 0Z" fill="${borderColors[2]}"/>
                <circle cx="35" cy="35" r="28" fill="white"/>
              </svg>
            </div>`;
        case 4:
            return `<div class="map-marker__border ${isHovered ? 'hovered' : ''}">
              <svg viewBox="0 0 70 70">
                <circle cx="35" cy="35" r="35" fill="${borderColors[0]}"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35L70 35Z" fill="${borderColors[1]}"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0V70Z" fill="${borderColors[2]}"/>
                <g clip-path="url(#clip)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35L70 35Z" fill="${borderColors[3]}"/>
                </g>
                <circle cx="35" cy="35" r="28" fill="white"/>
                <defs>
                <clipPath id="clip">
                <rect width="35" height="35" fill="white" transform="translate(35)"/>
                </clipPath>
                </defs>
              </svg>
            </div>`;
        default:
            return `<div class="map-marker__border ${layer === 'objects' && isHovered ? 'hovered' : ''}"
                    style="background-color: ${layer === 'partners' && (isHovered || isActive) ? borderColors[0] : '#fff'};
                    border: ${borderWidth}px solid ${borderColors[0]}"></div>`;
    }
};

const getTip = (numberColors, isActive, isVisited, tipColors) => {
    if (numberColors === 4 && isActive) {
        return {
            tip: `<div class="map-marker__tip map-marker__tip--double ${isVisited ? 'map-marker__tip--visited' : ''}" style="background-color: ${tipColors[0]}; color: ${tipColors[2]}"></div>`
        }
    }

    if (numberColors > 1 && isActive) {
        return {
            tip: `<div class="map-marker__tip map-marker__tip--double ${isVisited ? 'map-marker__tip--visited' : ''}" style="background-color: ${tipColors[0]}; color: ${tipColors[1]}"></div>`
        }
    }

    if (isActive) {
        return {
            tip: `<div class="map-marker__tip ${isVisited ? 'map-marker__tip--visited' : ''}" style="background-color: ${tipColors[0]}"></div>`
        }
    }

    return {
        point: ''
    }
};

const formatNames = (data, limit = 2) => {
  if (!Array.isArray(data) || data.length === 0) {
    return '';
  }

  const names = data.map(item => item.name).filter(name => name); // Извлекаем имена и фильтруем пустые значения
  const shownNames = names.slice(0, limit); // Берём первые N имен
  const remainingCount = names.length - shownNames.length; // Считаем, сколько осталось

  return remainingCount > 0 ? `${shownNames.join(', ')} (+${remainingCount})` : shownNames.join(', ');
};

const getTag = (layer, isCertified, isVisited, itemInfo) => {
    if (layer === 'partners' && !isCertified) {
        return '';
    }

    const {
      name, types
    } = itemInfo;

    return `
        <div class="map-marker__content ${isVisited ? 'map-marker__content--visited' : ''}">
          <p class="map-marker__name">${name}</p>
          <p class="map-marker__type">${formatNames(types)}</p>
        </div>
      </div>
    `;
};

const getIconContent = (options, isVisible, isVisited, layer) => {
    const {
        border, icon, tip, point, tag
    } = options;

    return `<div class="map-marker${isVisible ? '' : ' map-marker--invisible'}">
        <div class="map-marker__circle ${isVisited ? 'map-marker__circle--visited' : ''}${layer === 'partners' ? ' map-marker__circle--partner' : ''}">${border}${icon}</div>
        ${tip}${point}
        ${tag}`;
};

const markerLayout = (directions, iconOptions, layer, itemInfo, isHovered, isActive, isVisited, isVisible, isCertified) => {
    const borderColors = getBorderColors(directions);
    const borderWidth = window.innerWidth > 767 ? 7 : 5;
    const border = getBorder(borderColors, isHovered, layer, isActive, borderWidth);

    const src = getSrc(iconOptions, borderColors.length, layer);
    const icon = getIcon(src, isHovered || isActive, borderColors[0]);

    const tipOptions = getTip(borderColors.length, isActive, isVisited, borderColors);
    const tip = tipOptions.tip ?? '';
    const point = tipOptions.point ?? getPoint(isVisited, borderColors.length, borderColors[0]);

    const tag = getTag(layer, isCertified, isVisited, itemInfo);

    const options = {
        border,
        icon,
        tip,
        point,
        tag
    };

    return {
        iconContent: getIconContent(options, isVisible, isVisited, layer)
    };
};

const options = (directions) => {
    const borderColors = getBorderColors(directions);
    const iconColor = borderColors[Math.round(Math.random() * (borderColors.length - 1))];

    return {
        iconColor
    }
};

export default {
    markerLayout,
    options,
    formatNames
};
