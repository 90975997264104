<template>
  <div v-if="isVisible" :key="divKey">
    <ymap-marker :key="markerKey" :properties="properties" :coords="coords" :marker-id="item.id" :icon="icon"
      :options="options()" :cluster-name="layer" :hint-content="hintContent" @click="onClick()"
      @mouseenter="hovered = true" @mouseleave="hovered = false" />
  </div>
</template>

<script>
import { ymapMarker } from 'vue-yandex-maps';
import { mapGetters, mapActions } from 'vuex';
import markerHelper from '@/helpers/markerHelper';

export default {
  name: 'MapMarker',
  props: {
    item: Object
  },
  components: {
    ymapMarker
  },
  data() {
    return {
      interactionKey: 0,
      hovered: false,
      isVisible: true,
      icon: {
        layout: 'default#imageWithContent',
        imageHref: '/',
        imageSize: [70, 70]
      }
    }
  },
  watch: {
    properties() {
      this.interactionKey += 1;
    }
  },
  methods: {
    onClick() {
      this.startLoading();
      this.hovered = false;
      this.setVisited(this.item.id);
      this.setEntityId(this.item.id);
      this.$router.push(`/${this.layer}/${this.item.id}`);
      this.stopLoading();
    },
    isActive() {
      return this.item.id === this.itemId;
    },
    options() {
      return markerHelper.options(this.item.directions);
    },
    ...mapActions('loader', ['startLoading', 'stopLoading']),
    ...mapActions('entity', ['setEntityId', 'setVisited'])
  },
  computed: {
    divKey() {
      return `over-${this.item.id}`;
    },
    markerKey() {
      return `${this.item.id}-marker-${this.interactionKey}`;
    },
    properties() {
      const iconOptions = {
        image: this.item?.types[0]?.image || null,
        link: this.item?.types[0]?.link || null,
        isHovered: this.hovered
      };
      const itemInfo = {
        name: this.item.name,
        types: this.item.types
      };

      return markerHelper.markerLayout(this.item.directions, iconOptions, this.layer, itemInfo, this.hovered, this.isActive(), this.visited.includes(this.item.id), this.isVisible, this.item.is_sertified);
    },
    coords() {
      return [this.item.latitude, this.item.longitude];
    },
    hintContent() {
      if (this.layer === 'partners' && !this.item.is_sertified) {
        return `<div class="map-marker__content map-marker__content--hint ${this.item.visited ? 'map-marker__content--visited' : ''}">
                    <p class="map-marker__name">${this.item.name}</p>
                    <p class="map-marker__type">${markerHelper.formatNames(this.item.types)}</p>
                    </div>
                 </div>`;
      }
      return '';
    },
    ...mapGetters('layer', ['layer']),
    ...mapGetters('entity', ['itemId', 'visited'])
  }
}
</script>

<style scoped></style>
